import React, { useEffect, useState, useContext } from "react";
import Boost from "../../components/Boost";
import { initBackButton, useInitData } from "@telegram-apps/sdk-react";
import clickerLevels from "../../data/clickerLevels";
import energyLevels from "../../data/energyLevels";
import energyRecoveryLevels from "../../data/energyRecoveryLevels";
import flipLevels from "../../data/flipLevels";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {TimerContext} from "../../context/TimerContext";
import Menu from "../../components/Menu";
import useAppContext from '../../state/useAppContext';
import { getMaxEnergy } from "../../utils/getMaxEnergy";
import './Boosts.css'
import { useTranslation } from 'react-i18next';

const API_URL = process.env.REACT_APP_API_URL;

function Boosts() {
  const { t } = useTranslation();
  const [user, setUser] = useState(null);
  const initData = useInitData();
  const telegramUserId = initData.user.id;
  const userId = initData.user.id;
  const [backButton] = initBackButton();
  const navigate = useNavigate();
  const [boosts, setBoosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const { _id, id, score, clickLevel, energyLevel, energyRecoveryLevel, flipLevel } = user || {};
  const { startTimer, startFlipCoin } = useContext(TimerContext);
  const { actions } = useAppContext();
  const { setEnergy, setClickerLevel, setEnergyLevel, setEnergyRecoveryLevel, setFlipLevel } = actions;

  const handleStartShowFlipCoin = () => {
    startFlipCoin(3);
  };

  useEffect(() => {
    if (!telegramUserId) return;
    console.log("fetchUserData");
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/users/get-user/${telegramUserId}`,
        );
        const userData = response.data;

        if (!userData) {
          setLoading(false);
          return;
        }

        setUser({ ...userData });
      } catch (error) {
        console.error("Error fetching user:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [telegramUserId]);

  // Show back button and navigate on click
  backButton.on("click", () => {
    backButton.hide();
    navigate("/");
  });

  // Show back button on component mount
  useEffect(() => {
    backButton.show();
  }, [backButton]);

  // Fetch available boosts
  useEffect(() => {
    const fetchAvailableBoosts = async () => {
      try {
        const response = await axios.get(`${API_URL}/boosts/available/${_id}`);
        setBoosts(response.data.boosts);
      } catch (error) {
        console.error("Error fetching boosts:", error);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchAvailableBoosts();
    }
  }, [id]);

  // Function to use a boost
  const handleUseBoost = async (boostId) => {
    try {
      const response = await axios.post(`${API_URL}/boosts/use`, {
        userId: _id,
        dailyBoostId: boostId,
      });

      if (response.data.success) {
        // Update boosts after using one
        setBoosts(
          boosts.map((boost) =>
            boost._id === boostId
              ? {
                  ...boost,
                  availableCount: boost.availableCount - 1,
                }
              : boost,
          ),
        );

        // Get the boost that was just used
        const usedBoost = boosts.find(boost => boost._id === boostId);
        if (usedBoost) {
          if (usedBoost.name === 'Turbo tap') {
            backButton.hide();
            navigate('/');
          } else if (usedBoost.name === 'Recharger') {
            const maxEnergy = getMaxEnergy(user.energyLevel);
            setEnergy(maxEnergy);
            backButton.hide();
            navigate('/');
          }
        }
      }
    } catch (error) {
      console.error("Error using boost:", error);
    }
  };

  // Handle loading state
  if (loading || !userId) {
    return <div>Loading....</div>;
  }

  const incrementClickerLevel = async () => {
    if (user && clickLevel) {
        const price = clickerLevels[clickLevel]?.value ?? Infinity;
        if (score >= price) {
            await setClickerLevel(clickLevel + 1);
            setUser(prev => ({
                ...prev,
                clickLevel: clickLevel + 1,
                score: score - price
            }));
        }
    }
  };

  const incrementEnergyLevel = async () => {
    if (user && energyLevel) {
        const price = energyLevels[energyLevel]?.value ?? Infinity;
        if (score >= price) {
            await setEnergyLevel(energyLevel + 1);
            setUser(prev => ({
              ...prev,
              energyLevel: energyLevel + 1,
              score: score - price
          }));
        }
    }
  };

  const incrementEnergyRecoveryLevel = async () => {
    if (user && energyRecoveryLevel) {
        const price = energyRecoveryLevels[energyRecoveryLevel]?.value ?? Infinity;
        if (score >= price) {
            await setEnergyRecoveryLevel(energyRecoveryLevel + 1);
            setUser(prev => ({
                ...prev,
                energyRecoveryLevel: energyRecoveryLevel + 1,
                score: score - price
            }));
        }
    }
  };

  const incrementFlipLevel = async () => {
    if (user && flipLevel) {
        const price = flipLevels[flipLevel]?.value ?? Infinity;
        if (score >= price) {
            await setFlipLevel(flipLevel + 1);
            setUser(prev => ({
                ...prev,
                flipLevel: flipLevel + 1,
                score: score - price
            }));
        }
    }
  };

  // Fix the clickerLevel reference in isMaxClickerLevel calculation
  const isMaxClickerLevel = user?.clickLevel >= clickerLevels.length;
  const nextClickerLevelValue = isMaxClickerLevel
    ? "Max Level"
    : (clickerLevels[user?.clickLevel]?.value ?? Infinity);

  const isMaxEnergyLevel = energyLevel >= energyLevels.length;
  const nextEnergyLevelValue = isMaxEnergyLevel
    ? "Max Level"
    : (energyLevels[energyLevel]?.value ?? Infinity);

  const isMaxEnergyRecoveryLevel = energyRecoveryLevel >= energyRecoveryLevels.length;
  const nextEnergyRecoveryLevelValue = isMaxEnergyRecoveryLevel
    ? "Max Level"
    : (energyRecoveryLevels[energyRecoveryLevel]?.value ?? Infinity);

  const isMaxFlipLevel = flipLevel >= flipLevels.length;
  const nextFlipLevelValue = isMaxFlipLevel
    ? t('Max Level')
    : (flipLevels[flipLevel]?.value ?? Infinity);

  return (
    <div className="page">
    <div className="tasks-page">
      <h2 className="tasks-header">{t('Free daily boosters')}</h2>
      <div className="booster-container">
        {boosts.map((boost) => (
          <div
            key={boost._id}
            className={`task-item ${boost.availableCount <= 0 ? "inactive" : ""}`}
            onClick={() => {
                if (boost.availableCount > 0) {
                    handleUseBoost(boost._id)
                    if (boost.name === 'Turbo tap') {
                        handleStartShowFlipCoin();
                    }
                    if (boost.name === 'Recharger') {
                       
                    }
                }
            }
            }
          >
            {boost.name === 'Turbo tap' && <span>🚀</span>}
            {boost.name === 'Recharger' && <span>♻️</span>}
            {t(boost.name)} <br /> {boost.availableCount}/{boost.count} {t('available')}
          </div>
        ))}
      </div>
      <h2 className="tasks-header">{t('Boosters')}</h2>
      <div className="boosts-list">
      
        <Boost
          icon={"👆"}
          title={t('Multitap')}
          clickerLevel={user?.clickLevel}
          incrementLevel={incrementClickerLevel}
          isMaxLevel={isMaxClickerLevel}
          nextLevelValue={nextClickerLevelValue}
          score={score}
        />
        
        <Boost
          icon={'⚡️'}
          title={t('Recharging Speed')}
          clickerLevel={energyRecoveryLevel}
          incrementLevel={incrementEnergyRecoveryLevel}
          isMaxLevel={isMaxEnergyRecoveryLevel}
          nextLevelValue={nextEnergyRecoveryLevelValue}
          score={score}
        />

      <Boost
          icon={"🔋"}
          title={t('Energy Limit')}
          clickerLevel={energyLevel}
          incrementLevel={incrementEnergyLevel}
          isMaxLevel={isMaxEnergyLevel}
          nextLevelValue={nextEnergyLevelValue}
          score={score}
        />
        <Boost
          icon={'🔥'}
          title={t('Flip Probability')}
          clickerLevel={flipLevel}
          incrementLevel={incrementFlipLevel}
          isMaxLevel={isMaxFlipLevel}
          nextLevelValue={nextFlipLevelValue}
          score={score}
        />
      </div>
    </div>
    {/* <div className="menu-container">
        <Menu/>
    </div> */}
    </div>
  );
}

export default Boosts;
