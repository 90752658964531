import leagues from "../data/leagues.";
import { useTranslation } from 'react-i18next';

function League({league}) {
    const { t } = useTranslation();
    const currentLeague = leagues[league - 1] ?? leagues[leagues.length - 1];
    return <div className="league">{t(currentLeague.name)}</div>
}

export default League
