import React, { useReducer, useEffect, useCallback, useRef, useContext, useMemo } from 'react';
import { useTelegram } from '../hooks/useTelegram';
import {useInitData} from "@telegram-apps/sdk-react";
import AppContext from './AppContext'
import { appReducer, initialState } from './appReducer';
import * as usersApi from '../api/usersApi';
import * as tasksApi from '../api/tasksApi';
import * as boostsApi from '../api/boostsApi';
import axios from 'axios';
import clickerLevels from "../data/clickerLevels";
import energyLevels from "../data/energyLevels";
import energyRecoveryLevels from "../data/energyRecoveryLevels";
import flipLevels from "../data/flipLevels";
import {getMaxEnergy} from "../utils/getMaxEnergy";
import { useNavigate, useLocation } from 'react-router-dom';
import { TimerContext } from '../context/TimerContext';
import { useTranslation } from 'react-i18next';
import '../i18n';
import { debounce } from 'lodash';

const API_URL = process.env.REACT_APP_API_URL;
const SCORE_STORAGE_KEY = 'score';
const ENERGY_STORAGE_KEY = 'energy';
const CLOSE_APP_TIME_STORAGE_KEY = 'closeAppTime';


export const AppProvider = ({ children }) => {
    console.log('AppProvider rendered');
    const location = useLocation();
    const navigate = useNavigate();
    const [state, dispatch] = useReducer(appReducer, initialState);
    //const { user: telegramUser } = useTelegram();
    const initData = useInitData()
    const telegramUser = initData.user;
    const languageCode = telegramUser.languageCode;
    console.log('initData!', initData);
    const timeoutRef = useRef(null);
    const { energy, energyLevel, energyRecoveryLevel } =  state.user || {};
    const maxEnergy = getMaxEnergy(energyLevel);
    const energyRecovery = energyRecoveryLevels[energyRecoveryLevel - 1]?.score || 1;
    const { isRunning } = useContext(TimerContext);
    const { i18n } = useTranslation();

    const updateScoreInLocalStorage = useCallback((score) => {
        localStorage.setItem(SCORE_STORAGE_KEY, +localStorage.getItem(SCORE_STORAGE_KEY) + score);
    }, []);

    const updateEnergyInLocalStorage = useCallback((energy) => {
        localStorage.setItem(ENERGY_STORAGE_KEY, +localStorage.getItem(ENERGY_STORAGE_KEY) - energy);
    }, []);

    const updateCloseAppTimeInLocalStorage = useCallback((closeAppTime) => {
        localStorage.setItem(CLOSE_APP_TIME_STORAGE_KEY, closeAppTime);
    }, []);

    const setLocalStorage = useCallback((score, energy, closeAppTime) => {
        localStorage.setItem(SCORE_STORAGE_KEY, score);
        localStorage.setItem(ENERGY_STORAGE_KEY, energy);
        localStorage.setItem(CLOSE_APP_TIME_STORAGE_KEY, closeAppTime);
    }, []);

    const getLocalStorage = useCallback(() => {
        const score = +localStorage.getItem(SCORE_STORAGE_KEY);
        const energy = +localStorage.getItem(ENERGY_STORAGE_KEY);
        const closeAppTime = +localStorage.getItem(CLOSE_APP_TIME_STORAGE_KEY);
        return { score, energy, closeAppTime };
    }, []);


    const fetchUser = useCallback(async (telegramUserId) => {
        console.log('fetchUser', telegramUserId);
        if (!telegramUserId) return;
        
        // Check if user data already exists and is not empty
        if (state.user && Object.keys(state.user).length > 0) {
            return;
        }

        //let { score, energy, closeAppTime } = getLocalStorage();

        dispatch({ type: 'SET_LOADING', payload: true });
        try {
            const response = await axios.get(`${API_URL}/users/get-user/${telegramUserId}`);
            const userData = response.data;

            if (userData) {
                const { energyLevel, energyRecoveryLevel, league } = userData;
                // if (!score) {
                //     score = userData.score;
                // }
                // if (!energy) {
                //     energy = userData.energy;
                // }
                // if (!closeAppTime) {
                //     closeAppTime = userData.closeAppTime;
                // }
                let score = userData.score;
                let energy = userData.energy;
                let closeAppTime = userData.closeAppTime;
                const maxEnergy = energyLevels[energyLevel - 1]?.score || 0;
                const energyRecovery = energyRecoveryLevels[energyRecoveryLevel - 1]?.score || 1;
                let accumulatedEnergy = closeAppTime ? Math.floor((Date.now() - closeAppTime) * energyRecovery / 1000) : 0;
                const newEnergy = Math.min(energy + accumulatedEnergy, maxEnergy);
                const updatedUserData = { ...userData, energy: newEnergy, score: score, closeAppTime: closeAppTime,  };
                //setLocalStorage(score, newEnergy, Date.now());
                dispatch({ type: 'SET_USER', payload: updatedUserData });
            }
        } catch (error) {
            dispatch({ type: 'SET_IS_NEW_USER', payload: true });
            navigate('/welcome');
        } finally {
            dispatch({ type: 'SET_LOADING', payload: false });
        }
    }, [state.user, navigate]);

    const updateUser = useCallback(async (userData) => {
        if (!state.user) return;

        try {
            const updatedUser = await usersApi.updateUser({
                id: state.user.id,
                ...userData,
            });
            dispatch({ type: 'SET_USER', payload: updatedUser });
        } catch (error) {
            dispatch({ type: 'SET_ERROR', payload: error.message });
        }
    }, [state.user]);

    const createUser = useCallback(async (userData) => {
        try {
            const newUser = await usersApi.createUser(userData);
            dispatch({ type: 'SET_USER', payload: newUser });
        } catch (error) {
            dispatch({ type: 'SET_ERROR', payload: error.message });
        }
    }, []);

    const modifyEnergy = useCallback(async (energy) => {
        if (!state.user) return;

        dispatch({ type: 'MODIFY_ENERGY', payload: energy });

        try {
            // Optionally, update the backend
            await axios.post(`${API_URL}/users/update-user`, {
                id: state.user.id,
                energy: state.user.energy + energy,
                closeAppTime: Date.now(),
            });
        } catch (error) {
            console.error('Error updating energy:', error);
        }
    }, [state.user]);

    const setEnergy = useCallback(async (energy) => {
        if (!state.user) return;

        dispatch({ type: 'SET_ENERGY', payload: energy });

        try {
            // Optionally, update the backend
            await axios.post(`${API_URL}/users/update-user`, {
                id: state.user.id,
                energy: energy,
                closeAppTime: Date.now(),
            });
        } catch (error) {
            console.error('Error updating energy:', error);
        }
    }, [state.user]);

    const debouncedUpdateUser = useCallback(
        debounce(async (userId, newScore, newEnergy) => {
            try {
                await axios.post(`${API_URL}/users/update-user`, {
                    id: userId,
                    score: newScore,
                    energy: newEnergy,
                    closeAppTime: Date.now(),
                });
            } catch (error) {
                console.error('Error updating score:', error);
            }
        }, 700), []);

    const updateUserDebounced = useCallback(async (score, energy = 0) => {
        const newScore = state.user.score + score;
        const newEnergy = state.user.energy - energy;

        // Update local state immediately
        dispatch({ type: 'MODIFY_SCORE', payload: score });
        dispatch({ type: 'MODIFY_ENERGY', payload: -energy });

        // Debounced API call
        debouncedUpdateUser(state.user.id, newScore, newEnergy);
    }, [state.user, debouncedUpdateUser]);

    const modifyScore = useCallback(async (score) => {
        const multiplier = isRunning ? 2 : 1; // Double the score during turbo mode
        const finalScore = score * multiplier;
        dispatch({ type: 'MODIFY_SCORE', payload: finalScore });
        try {
            await axios.post(`${API_URL}/users/update-user`, {
                id: state.user.id,
                score: state.user.score + finalScore,
                closeAppTime: Date.now(),
            });
        } catch (error) {
            console.error('Error updating score:', error);
        }
    }, [state.user, isRunning]);

    const fetchTasks = useCallback(async () => {
        dispatch({ type: 'SET_LOADING', payload: true });
        try {
            const tasks = await tasksApi.fetchTasks();
            dispatch({ type: 'SET_TASKS', payload: tasks });
        } catch (error) {
            dispatch({ type: 'SET_ERROR', payload: error.message });
        }
    }, []);

    const fetchBoosts = useCallback(async () => {
        dispatch({ type: 'SET_LOADING', payload: true });
        try {
            const boosts = await boostsApi.fetchBoosts();
            dispatch({ type: 'SET_BOOSTS', payload: boosts });
        } catch (error) {
            dispatch({ type: 'SET_ERROR', payload: error.message });
        }
    }, []);

    const setClickerLevel = useCallback(async (level) => {
        if (!state.user) return;
        const price = clickerLevels[state.user.clickLevel]?.value ?? Infinity;
        
        // Check if user has enough score
        if (state.user.score < price) return;

        dispatch({ type: 'SET_CLICKER_LEVEL', payload: level });
        dispatch({ type: 'MODIFY_SCORE', payload: -price }); // Subtract price from score
        
        try {
            await axios.post(`${API_URL}/users/update-user`, {
                id: state.user.id,
                clickLevel: level,
                score: state.user.score - price,
            });
        } catch (error) {
            console.error('Error updating clicker level:', error);
            // Rollback changes if update fails
            dispatch({ type: 'SET_CLICKER_LEVEL', payload: level - 1 });
            dispatch({ type: 'MODIFY_SCORE', payload: price });
        }
    }, [state.user]);

    const setEnergyLevel = useCallback(async (level) => {
        if (!state.user) return;
        const price = energyLevels[state.user.energyLevel]?.value ?? Infinity;
        
        if (state.user.score < price) return;

        dispatch({ type: 'SET_ENERGY_LEVEL', payload: level });
        dispatch({ type: 'MODIFY_SCORE', payload: -price });
        
        try {
            await axios.post(`${API_URL}/users/update-user`, {
                id: state.user.id,
                energyLevel: level,
                score: state.user.score - price,
            });
        } catch (error) {
            console.error('Error updating energy level:', error);
            dispatch({ type: 'SET_ENERGY_LEVEL', payload: level - 1 });
            dispatch({ type: 'MODIFY_SCORE', payload: price });
        }
    }, [state.user]);

    const setEnergyRecoveryLevel = useCallback(async (level) => {
        if (!state.user) return;
        const price = energyRecoveryLevels[state.user.energyRecoveryLevel]?.value ?? Infinity;
        
        if (state.user.score < price) return;

        dispatch({ type: 'SET_ENERGY_RECOVERY_LEVEL', payload: level });
        dispatch({ type: 'MODIFY_SCORE', payload: -price });
        
        try {
            await axios.post(`${API_URL}/users/update-user`, {
                id: state.user.id,
                energyRecoveryLevel: level,
                score: state.user.score - price,
            });
        } catch (error) {
            console.error('Error updating energy recovery level:', error);
            dispatch({ type: 'SET_ENERGY_RECOVERY_LEVEL', payload: level - 1 });
            dispatch({ type: 'MODIFY_SCORE', payload: price });
        }
    }, [state.user]);

    const setFlipLevel = useCallback(async (level) => {
        if (!state.user) return;
        const price = flipLevels[state.user.flipLevel]?.value ?? Infinity;

        if (state.user.score < price) return;

        dispatch({ type: 'SET_FLIP_LEVEL', payload: level });
        dispatch({ type: 'MODIFY_SCORE', payload: -price });

        try {
            await axios.post(`${API_URL}/users/update-user`, {
                id: state.user.id,
                flipLevel: level,
                score: state.user.score - price,
            });
        } catch (error) {
            console.error('Error updating flip level:', error);
            dispatch({ type: 'SET_FLIP_LEVEL', payload: level - 1 });
            dispatch({ type: 'MODIFY_SCORE', payload: price });
        }
    }, [state.user]);

    useEffect(() => {
        if (languageCode && i18n.language !== languageCode) {
            console.log('Changing language to:', languageCode);
            i18n.changeLanguage(languageCode);
        }
    }, [languageCode, i18n]);

    useEffect(() => {
        if (!telegramUser?.id) return;
        if (location.pathname !== '/welcome') {
            fetchUser(telegramUser.id);
        }
    }, [telegramUser, fetchUser]);

    useEffect(() => {
        if (energy === undefined || energyRecovery === undefined || maxEnergy === undefined) {
            return;
        }

        const incrementEnergy = () => {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;

            if (maxEnergy > energy) {
                if (energy + energyRecovery <= maxEnergy) {
                    dispatch({ type: 'MODIFY_ENERGY', payload: energyRecovery });
                } else {
                    dispatch({ type: 'SET_ENERGY', payload: maxEnergy });
                }
            }
        };

        // Clear the timeout if energy is at max
        if (energy >= maxEnergy) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
            return;
        }

        if (!timeoutRef.current) {
            timeoutRef.current = setTimeout(incrementEnergy, 1000);
        }
    }, [energy, energyRecovery, maxEnergy, setEnergy]);

    // useEffect(() => {
    //     const nextLeague = leagues[league] ?? null;
    //     if (nextLeague) {
    //         dispatch({ type: 'SET_NEXT_LEAGUE', payload: nextLeague });
    //     }
    // }, [league]);

    if (state.isLoading) {
        return <div>Loading...</div>;
    }

    if (state.error) {
        return <div>Error: {state.error}</div>;
    }



    return (
        <AppContext.Provider value={{
            ...state,
            actions: {
                fetchUser,
                updateUser,
                updateUserDebounced,
                modifyEnergy,
                modifyScore,
                setEnergy,
                setEnergyLevel,
                setClickerLevel,
                setEnergyRecoveryLevel,
                setFlipLevel,
                fetchTasks,
                fetchBoosts,
                createUser,
                setLocalStorage,
                getLocalStorage,
                updateScoreInLocalStorage,
                updateEnergyInLocalStorage,
                updateCloseAppTimeInLocalStorage,
            },
            telegram: {
                user: telegramUser,
            }
        }}>
            {children}
        </AppContext.Provider>
    );
};
