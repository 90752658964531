import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {initBackButton, initUtils, useInitData} from "@telegram-apps/sdk-react";
import {useNavigate} from "react-router-dom";
import Modal from "../../components/modal/Modal";
import useAppContext from '../../state/useAppContext';
import Menu from "../../components/Menu";
import './Tasks.css'
import { useTranslation } from 'react-i18next';
import CheckingAnimation from '../../components/CheckingAnimation/CheckingAnimation';
import Popup from '../../components/Popup/Popup';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css'; 

const API_URL = process.env.REACT_APP_API_URL;

function Tasks() {
    const { t } = useTranslation();
    const { user, actions, isLoading,  error } = useAppContext();
    const { modifyScore } = actions;
    const initData = useInitData();
    const _id = user ? user._id : null;
    const userId = initData.user.id;
    const [tasks, setTasks] = useState([]);
    const utils = initUtils();
    const [backButton] = initBackButton();
    const navigate = useNavigate();
    const [activeTaskId, setActiveTaskId] = useState(null);
    const [activeTask, setActiveTask] = useState(null)
    const [taskDescriptions, setTaskDescriptions] = useState({});
    const [checking, setChecking] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');
    const [filteredTasks, setFilteredTasks] =  useState([])

    const getReferrers = async (userId) => {
        const res = await axios.get(`${API_URL}/users/get-referrers/${userId}`);
        return res.data;
    }

    console.log('user', user)
    const closeModal = () => {
        console.log('closeModal')
        setActiveTaskId(null);
        setActiveTask(null);
    };

    backButton.on('click', () => {
        backButton.hide();
        navigate('/');
    });

    useEffect(() => {
        backButton.show();
    }, [backButton]);

    useEffect(() => {
        const fetchTasks = async () => {
            if (_id) {
                const result = await axios.get(`${API_URL}/tasks/${user._id}`);
                console.log(result)
                setTasks(result.data.tasks);
            }
        };
        fetchTasks();
    }, [_id]);

    useEffect(() => {
        const filtered = tasks.filter(task => {
            if (task.type !== 'league' || task.isCompleted) {
                return task;
            } else {
                if (user.score > task.coins) {
                    return task;
                }
            }
        })
        setFilteredTasks(filtered)
    }, [tasks])


    const checkTaskCompletion = async (taskId) => {
        try {
            const response = await axios.get(`${API_URL}/tasks/${_id}/completed/${taskId}`);
            return response.data.isCompleted;
        } catch (error) {
            console.error('Error checking task completion:', error);
            return false;
        }
    };

    const startTask = async (task) => {
        try {
            const response = await axios.post(`${API_URL}/tasks/start`, {taskId: task._id, userId: _id});
            if (response.data.success) {
                // Update task state to reflect that it has started
                setTasks(tasks.map(t => t._id === task._id ? {...t, isStarted: true} : t));
            } else {
                console.error('Error starting task:', response.data.message);
            }
        } catch (error) {
            console.error('Error starting task:', error);
        }
    };

    const startAndCompleteTask = async (task) => {
        try {
            const response = await axios.post(`${API_URL}/tasks/start-and-complete`, {taskId: task._id, userId: _id});
            if (response.data.success) {
                // Update task state to reflect that it has started
                setTasks(tasks.map(t => t._id === task._id ? {...t, isStarted: true, isCompleted: true} : t));
                return true;
            } else {
                console.error('Error starting or completing task:', response.data.message);
            }
        } catch (error) {
            console.error('Error starting or completing task:', error);
            return false;
        }
    };

    const getTaskDescription = async (task) => {  
        if (task.type === 'invite_friends') {
            const referrers = await getReferrers(userId);
            if (referrers.length >= 10) {
                return 'You have ' + referrers.length + ' friends. You can complete this task!';
            } else {
                return 'You need to invite ' + (10 - referrers.length) + ' friends to complete this task';
            }
        }
        return '';
    }

    const getTaskAction = (task) => {
        console.log('getTaskAction', task)

        if ((task.type === 'welcome_bonus' || task.type === 'league') && !task.isStarted) {
            startTask(task);
            return;
        }

        if (task.type === 'invite_friends') {
            const referrers = getReferrers(userId);
            if (referrers.length >= 10) {
                completeTask(task);
                return;
            } else {
                return;
            }
        }

        if (!task.isStarted) {
            console.log('startTask')
            startTask(task);
        }

        if (task.type === 'subscribe_channel') {
            return () => utils.openTelegramLink(task.url);
        }
        if (task.type === 'welcome_bonus') {
            // return () => completeTask(task._id);
        }
        if (task.type === 'visit_link') {
            return () => utils.openLink(task.url);
        }
    }

    const completeTask = async (task) => {

        if (task.type === 'subscribe_channel') {
            const subscriptionCheck = await axios.post(`${API_URL}/tasks/check-subscription`, {userId: userId});

            if (!subscriptionCheck.data.isSubscribed) {
                alert('You are not subscribed to the channel!');
                return;
            }
        }
        if (task.type === 'welcome_bonus' || task.type === 'league') {
            startTask(task);
        }
        // if (task.type === 'visit_link') {
        // }

        const response = await axios.post(`${API_URL}/tasks/complete`, {taskId: task._id, userId: _id});
        if (response.data.success) {
            modifyScore(task.coins);
            setTasks(tasks.map(t => t._id === task._id ? {...t, isCompleted: true} : t));
        }
    };

    useEffect(() => {
        const fetchTaskDescriptions = async () => {
            const descriptions = {};
            for (const task of tasks) {
                descriptions[task._id] = await getTaskDescription(task);
            }
            setTaskDescriptions(descriptions);
        };
        fetchTaskDescriptions();
    }, [tasks]);

    const handleSpecialTask = async (task) => {
        let errorMessage = 'Task not Completed';
        setChecking(true);
        try {
            let result;
            switch (task.type) {
                case 'welcome_bonus':
                    result = await startAndCompleteTask(task);
                    console.log('result', result)
                    break;
                case 'invite_friends':
                    const referrers = await getReferrers(userId);
                    result = referrers.length >= 10;
                    errorMessage = 'You need to invite ' + (10 - referrers.length) + ' more friends to complete this task';
                    break;
                case 'subscribe_channel':
                    utils.openTelegramLink(task.url);
                    const subscriptionCheck = await axios.post(`${API_URL}/tasks/check-subscription`, {userId: userId});
                    result = subscriptionCheck.data.isSubscribed;
                    break;
                case 'league':
                    result = user.score >= task.coins;
                    errorMessage = 'You need to earn ' + (task.coins - user.score) + ' more coins to complete this task';
                    break;
                default:
                    result = false;
            }

            if (result) {
                await completeTask(task);
                setTasks(tasks.map(t => t._id === task._id ? {...t, isCompleted: true} : t));
            } else {
                // Replace the alert with this:
                setPopupMessage(t(errorMessage));
            }
        } catch (error) {
            console.error('Error handling special task:', error);
            // Replace the alert with this:
            setPopupMessage(t(errorMessage));
        } finally {
            setChecking(false);
        }
    };

    const handleTaskClick = (task) => {
        if (['welcome_bonus', 'invite_friends', 'league'].includes(task.type)) {
            handleSpecialTask(task);
        } else {
            setActiveTaskId(activeTaskId !== task._id && !task.isCompleted && task._id);
            setActiveTask(task)
        }
    };

    useEffect(() => {
        if (popupMessage) {
            const timer = setTimeout(() => {
                setPopupMessage('');
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [popupMessage]);

    return (
        <div className="page">
            {checking && <CheckingAnimation />}
            {popupMessage && <Popup message={popupMessage} />}
            <div className="tasks-page">
                <h2 className="tasks-header">{t('Onboarding tasks')}</h2>
                {filteredTasks.length > 3 ? (
                    <Swiper
                    spaceBetween={10}
                    slidesPerView={'auto'} 
                    >

                        {Array.from({ length: Math.ceil(filteredTasks.length / 3) }).map((_, index) => {
                        const tasksSlice = filteredTasks.slice(index * 3, index * 3 + 3); 
                        return (

                            <SwiperSlide key={index}>
                                                            <ul className="task-list">

                                    {tasksSlice.map(task => (
                                        <li onClick={() => !task.isCompleted ? handleTaskClick(task) : null}
                                        key={task._id}
                                        className={`task-item ${task.isCompleted ? 'inactive' : ''}`}
                                    >
                                        <div className='task-item__element'>
                                            <div className='task-item__element-icon'>
                                                <div className="task-icon">🎉</div>
                                            </div>
                                            <div>
                                                <div className="task-title">{t(task.name)}</div>
            
                                                <div className="task-coins">🟡  +{task.coins}</div>
            
                                            </div>
                                         </div>
                                         <button className="task-action" onClick={() => {setActiveTaskId(task._id); setActiveTask(task)}}>
                                         <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.53125 5.46875C6.8125 5.78125 6.8125 6.25 6.53125 6.53125L1.53125 11.5312C1.21875 11.8438 0.75 11.8438 0.46875 11.5312C0.15625 11.25 0.15625 10.7812 0.46875 10.5L4.9375 6.03125L0.46875 1.53125C0.15625 1.25 0.15625 0.78125 0.46875 0.5C0.75 0.1875 1.21875 0.1875 1.5 0.5L6.53125 5.46875Z" fill="white"/>
                                         </svg>
            
                                        </button>
                                        
                                    </li>
                                    ))}
                            </ul>

                            </SwiperSlide>

                        );
                    })}

                    </Swiper>
                ) : (
                <ul className="task-list">
                    {filteredTasks.map(task => (
                        <li onClick={() => !task.isCompleted ? handleTaskClick(task) : null}
                            key={task._id}
                            className={`task-item ${task.isCompleted ? 'inactive' : ''}`}
                        >
                            <div className='task-item__element'>
                                <div className='task-item__element-icon'>
                                    <div className="task-icon">🎉</div>
                                </div>
                                <div>
                                    <div className="task-title">{t(task.name)}</div>

                                    <div className="task-coins">🟡  +{task.coins}</div>

                                </div>
                             </div>
                             <button className="task-action" onClick={() => {setActiveTaskId(task._id); setActiveTask(task)}}>
                             <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.53125 5.46875C6.8125 5.78125 6.8125 6.25 6.53125 6.53125L1.53125 11.5312C1.21875 11.8438 0.75 11.8438 0.46875 11.5312C0.15625 11.25 0.15625 10.7812 0.46875 10.5L4.9375 6.03125L0.46875 1.53125C0.15625 1.25 0.15625 0.78125 0.46875 0.5C0.75 0.1875 1.21875 0.1875 1.5 0.5L6.53125 5.46875Z" fill="white"/>
                             </svg>

                            </button>
                            {/* {activeTaskId === task._id && !['welcome_bonus', 'invite_friends', 'league'].includes(task.type) && (
                                <Modal
                                    isOpen
                                    onClose={closeModal} // Close modal
                                    title={task.name}
                                >
                                    {task.description && <p>{t(task.description)}</p>}
                                    {taskDescriptions[task._id] && <p>{t(taskDescriptions[task._id])}</p>}
                                    {(task?.isStarted || task.type === 'league' || task.type === 'welcome_bonus') && (
                                        <button className="button-red" onClick={() => completeTask(task)}>
                                            {task.type === 'league' || task.type === 'welcome_bonus' ? t('Get the bonus') : t('Check task')}
                                        </button>
                                    )}
                                    {!task?.isStarted && (task.type !== 'league' && task.type !== 'welcome_bonus') && (
                                        <button className="button-red" onClick={() => getTaskAction(task)}>
                                            {t('Start with task')}
                                        </button>
                                    )}
                                </Modal>
                            )} */}
                        </li>
                    ))}
                </ul>       
                )}

                {activeTask && activeTaskId === activeTask._id && !['welcome_bonus', 'invite_friends', 'league'].includes(activeTask.type) && (
                    <Modal
                        isOpen
                        onClose={closeModal} // Close modal
                        title={activeTask.name}
                    >
                        {activeTask.description && <p>{t(activeTask.description)}</p>}
                        {taskDescriptions[activeTask._id] && <p>{t(taskDescriptions[activeTask._id])}</p>}
                        {(activeTask?.isStarted || activeTask.type === 'league' || activeTask.type === 'welcome_bonus') && (
                            <button className="button-red" onClick={() => completeTask(activeTask)}>
                                {activeTask.type === 'league' || activeTask.type === 'welcome_bonus' ? t('Get the bonus') : t('Check task')}
                            </button>
                        )}
                        {!activeTask?.isStarted && (activeTask.type !== 'league' && activeTask.type !== 'welcome_bonus') && (
                            <button className="button-red" onClick={() => getTaskAction(activeTask)}>
                                {t('Start with task')}
                            </button>
                        )}
                    </Modal>
                )}
                
            </div>
            {/* <div className="menu-container">
                <Menu/>
            </div> */}
        </div>
    );
}

export default Tasks;
